import React from "react";

function ObjectiveComponent() {
  return (
    <div className="objective-background">
      <div>
        <div
          className="section-header"
        >
          About Fake News Detective
        </div>
      </div>
      <div className="objective-description">
        The online world is rapidly being shrouded in falsehoods, but you can be
        our hope to find the mastermind behind the spread of fake news. Go
        through three levels of fact-finding and adventure. Understand why fake
        news spreads and hone your fake news detection skills through a series
        of exploration and puzzle-solving. This interactive educational game
        will help you to advance your skills in fighting fake news.
      </div>
    </div>
  );
}

export default ObjectiveComponent;
