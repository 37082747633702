import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./PlayForFreeComponent.css";
import "./HollowButton.css"; 

function PlayForFreeComponent() {
  const textRef = useRef(null);
  const buttonRef = useRef(null);
  const [textInView, setTextInView] = useState(false);
  const [buttonInView, setButtonInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target === textRef.current) {
              setTextInView(true);
            } else if (entry.target === buttonRef.current) {
              setButtonInView(true);
            }
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }
    if (buttonRef.current) {
      observer.observe(buttonRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (textRef.current) observer.unobserve(textRef.current);
      if (buttonRef.current) observer.unobserve(buttonRef.current);
    };
  }, []);

  return (
    <div className="play-for-free-container">
      <div className="play-for-free-now">
        <div>
          <div className="play-for-free-content">
            <div className="text-effect">
              {/* Text that appears on scroll */}
              <div
                ref={textRef}
                className={`border-box play-for-free-text ${textInView ? "fade-in" : "hidden"}`}
                data-text="Neon"
              >
                Your Mission: Uncover the Truth
              </div>
              <div className="spotlight"></div>
            </div>
            {/* <div className="play-for-free-note">
              This version can only be played in a desktop or laptop as it
              requires the use of arrow keys.
            </div> */}
            {/* Button that appears on scroll */}
            <button
              ref={buttonRef}
              className={`hollow-button ${buttonInView ? "fade-in" : "hidden"}`}
            >
              <Link to="/play" className="login-link">
                PLAY NOW!
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayForFreeComponent;
